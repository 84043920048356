<template>
  <div class="release">
    <div class="title">发布供需</div>
    <div class="main">
      <div class="text-title">
        <div class="afterX w42">标题</div>
        <el-input type="text" placeholder="请输入标题" v-model="texttitle" maxlength="64" show-word-limit>
        </el-input>
      </div>
      <div class="header">
        <!-- <div class="div" style="display: flex;align-items: center;">
          <div class="type">
            <el-select v-model="value" placeholder="选择供需类别">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span class="afterX">选择类别</span>
            <el-cascader v-model="optionValue" :options="classifylist"
              :props="{ multiple: true, checkStrictly: true, label: 'title', value: 'id', children: 'children' }"
              clearable>
            </el-cascader>
          </div>



          <div class="date">
            <el-date-picker v-model="value1" type="date" placeholder="截止日期">
            </el-date-picker>
          </div>
        </div> -->

        <div class="up-img">
          <span class="afterX">封面图片</span>
          <el-upload class="avatar-uploader" action="https://api2.dtcms.polydt.com/upload?water=0&thumb=0"
            :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>

      <div class="radio">
        <span class="afterX">供需类别</span>
        <el-radio-group v-model="type">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </div>

      <div class="radio">
        <span class="afterX">供需聘</span>
        <el-radio-group v-model="radioType">
          <el-radio :label="1">供</el-radio>
          <el-radio :label="2">需</el-radio>
          <el-radio :label="3">聘</el-radio>
        </el-radio-group>
      </div>
      <div class="label-box">
         <span class="afterX">标签内容</span>
        <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
        <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
          @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
        </el-input>
        <el-button v-else-if="!inputVisible && dynamicTags.length == 0" class="button-new-tag" size="small" @click="showInput">+ 添加标签</el-button>
      </div>

      <div class="textarea-box">
        <span class="afterX">内容介绍</span>
        <vue-editor v-model="content"></vue-editor>
      </div>
      <div class="confirm-button">
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { getSupplyclassification, createSupply, createPDSupply, updateImage } from "@/api";
export default {
  name: "release",
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "需求",
        },
        {
          value: "选项2",
          label: "供应",
        },
        {
          value: "选项3",
          label: "招聘",
        },
      ],
      value: "",
      value1: "",
      imageUrl: "",
      filePath: '',
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      texttitle: "",
      content: "",
      classifylist: [],
      optionValue: [],
      radioType: 1,
      type: 1,
    };
  },
  components: {
    VueEditor,
  },
  created() {
    createPDSupply().then(res => {

    })
    this.getSelectData();
  },
  methods: {
    getSelectData() {
      getSupplyclassification().then((res) => {
        console.log("供需分类", res.data.children);
        res.data.forEach(item => {
          item.children.forEach(child => {
            if (child.children.length < 1) {
              child.children = null;
            }
          })
        })
        this.classifylist = res.data;
      });
    },
    confirmdata() {
      console.log(this.content);
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      console.log(file);
      this.filePath = res[0].filePath;
      
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    submit() {
      const data = {
        title: this.texttitle,
        imgUrl: this.filePath,
        channelId: 185,
        albumMeta: "",
        albums: [],
        attachMeta: "",
        attachs: [],
        fieldMeta: "",
        fields: [
          { title: '供需类别', fieldName: 'for_need',fieldValue: this.type, channelId: 76, controlType: 'radio' },
          { title: '供需聘', fieldName: 'Employment', fieldValue: this.radioType, channelId: 76, controlType: 'radio' },
          { title: '联系人', fieldValue: null, channelId: 76 },
          { title: '手机号', fieldValue: null, channelId: 76 },
          { title: '邮箱', fieldValue: null, channelId: 76 },
        ],
        id: 0,
        source: '',
        status: 0,
        content: this.content,
        author: this.dynamicTags[0]
      }


      createSupply(data).then(res => {
        this.$router.push('/supplydemand');
        this.$message({
          message: "发布成功",
          type: "success",
        });

      })
    },
  },
};
</script>

<style lang="less" scoped>
.release {
  width: 1280px;
  margin: 30px auto;

  .title {
    background: white;
    padding: 20px;
    font-weight: bold;
    font-size: 21px;
    border-radius: 7px;
  }

  .main {
    width: 100%;
    padding-bottom: 20px;
    margin-top: 20px;
    background: white;
    border-radius: 7px;

    .header {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      div {
        margin-right: 10px;
      }

      .up-img {
        right: 20px;
      }
    }

    .radio {
      margin-left: 20px;
      display: flex;
      align-items: center;

      span {
        text-align: left;
        vertical-align: middle;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
      }
    }

    .label-box {
      // padding: 10px;
      // margin: 20px;
      margin: 20px 0 0 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;

      button {
        color: #4da1ff;
        border: 1px solid #4da1ff;
      }
    }

    .text-title {
      padding: 20px;
      display: flex;

      .el-input {
        height: 50px;

        /deep/ input {
          font-size: 23px;
          height: 50px;
        }
      }
    }

    .textarea-box {
      margin: 20px;
    }

    .confirm-button {
      padding: 0px 20px;
      text-align: right;
    }
  }
}

.input-new-tag {
  width: 120px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

/deep/.el-upload {
  border-radius: 20px;
  background: #e9e9e9;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  overflow: hidden;
}



.avatar {
  width: 180px;
  height: 120px;
  display: block;
}

.afterX {
  display: block;
  width: 60px;
  text-align: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
}

.w42 {
  width: 42px;
}

// .afterX::after {
//   content: '*';
//   color: #F56C6C;
//   margin-right: 4px;
// }
</style>